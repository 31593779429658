import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { change, getFormValues } from "redux-form";

import { addInstructions, removeInstructions } from "../../../actions/patient_new_instructions";
import remoteLog from "../../../common/logging";
import Loader from "../../common/loadingInProgress";
import { deployedUSA } from "../../../common/utils";
import { ARCH_BOTH } from "../../../common/prescription";
import {
  CASE_FULL,
  CASE_CBCT,
  CASE_PLAN_3D,
  CASE_RETAINERS,
  CASE_SHORT,
  CASE_SINGLE_ARCH,
  CASE_SUPER_SHORT,
  S_UNFILLED_CASE,
  isRetainersCourse,
  isCBCTCourse,
  isChildrenCourse,
  CO_TAG_FULL_3_STEPS_CBCT,
  CO_TAG_TEEN_3_STEPS_CBCT,
  COURSE_VERSION_V2,
} from "../../../common/courses";
import ImpressionScanOptions from "../impression_scan_options";
import { CASE_RX_TYPE_STANDARD } from "../../../common/constants";
import { rushCorrectionOn } from "../../../actions/post_patient";

const predict_link = "/api/v1/manuals/document/51";

const mapStateToProps = (state) => {
  return {
    user: state.user,
    patient: state.patient,
    lang: state.intl,
    instructions: state.instructions,
    courseInstallment: state.courseInstallment,
    // rush_correction: state.rushCorrection,
    formValues: getFormValues("correction")(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addInstruction: (data) => dispatch(addInstructions(data)),
    removeInstructions: (data) => dispatch(removeInstructions(data)),
    change: (form, field, value) => dispatch(change(form, field, value)),
    rushCorrection: (data) => dispatch(rushCorrectionOn(data)),
  };
};

class PatientUpdateInstructionsCourse extends Component {
  constructor(props) {
    super(props);
    this.addCondition = this.addCondition.bind(this);
    this.selectCourse = this.selectCourse.bind(this);
    this.selectDeepCBCTAnalysis = this.selectDeepCBCTAnalysis.bind(this);
    this.addComment = this.addComment.bind(this);
  }

  componentDidCatch(e) {
    remoteLog(e, "patient_update_instructions_course");
  }

  componentDidMount() {
    const { patient } = this.props;
    !((patient || {}).course || {}).course ||
      this.selectCourse(
        {
          C_UNLIMITED: CASE_CBCT,
          C_FULL: CASE_FULL,
          C_SHORT: CASE_SHORT,
          C_ONE_JAW: CASE_SINGLE_ARCH,
          C_SUPER_SHORT: CASE_SUPER_SHORT,
          C_3D_PLAN: CASE_PLAN_3D,
          C_RETAINERS: CASE_RETAINERS,
        }[patient.course.course],
      );

    if (
      (patient && patient.deep_cbct) ||
      (patient.course &&
        [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(
          patient.course.course_option_tag,
        ))
    ) {
      this.props.addInstruction({ deep_cbct: true });
    }

    this.props.addInstruction({ condition: patient.diagnosis });
    if (
      deployedUSA() === true &&
      patient.course.correction[patient.course.correction.length - 1].prescription !== null
    ) {
      this.addComment(
        patient.course.correction[patient.course.correction.length - 1].prescription.comment,
      );
    }
  }

  selectCourse(data) {
    const course_id = parseInt(data);

    if (
      this.props.instructions &&
      this.props.instructions.course_id &&
      this.props.instructions.course_id !== parseInt(data)
    ) {
      if (
        !this.props.courseInstallment[parseInt(data)].includes(
          this.props.instructions.payment_option_id,
        )
      ) {
        const { can_edit_payment_option } = this.props.patient;
        if (can_edit_payment_option) {
          this.props.removeInstructions({ payment_option_id: "" });
          this.props.addInstruction({ payment_option_id: null });
        }
      }
    }
    this.props.addInstruction({ course_id: course_id });
    if (!isNaN(course_id)) {
      this.props.setDirty("course_id", course_id);
    }

    const correctionFormValues = this.props.formValues;
    if (
      correctionFormValues &&
      correctionFormValues.arch &&
      correctionFormValues.arch == ARCH_BOTH
    ) {
      if (course_id == CASE_SINGLE_ARCH || course_id == CASE_SUPER_SHORT) {
        this.props.change("correction", "arch", null);
      }
    }

    if (isCBCTCourse(course_id) || isChildrenCourse(course_id) || isRetainersCourse(course_id)) {
      this.props.addInstruction({ deep_cbct: false });
    }
    if (this.props.instructions && course_id == CASE_RETAINERS) {
      this.props.addInstruction({ sber_credit: false });
    }
  }

  selectDeepCBCTAnalysis(data) {
    this.props.addInstruction({ deep_cbct: Boolean(data.checked) });
    this.props.setDirty("deep_cbct", Boolean(data.checked));
  }

  addCondition(data) {
    this.props.addInstruction({ condition: data });
    this.props.setDirty("diagnosis", data);
  }

  addComment(data) {
    this.props.change("correction", "comment", data);
  }

  render() {
    const rxTypeId = this.props.rxTypeId;
    if (this.props.patient && this.props.patient.patient_id) {
      const { patient } = this.props;
      const { material } = patient
        ? patient.course.correction[patient.course.correction.length - 1].prescription
        : null;

      let course_id = null;
      let show_cbct_option = false;

      if (this.props.instructions && Object.keys(this.props.instructions).length > 0) {
        course_id = this.props.instructions.course_id || null;
        show_cbct_option =
          !isCBCTCourse(course_id) &&
          !isChildrenCourse(course_id) &&
          !isRetainersCourse(course_id) &&
          course_id !== null;
      }
      const show_rush_prod =
        patient.course.correction[patient.course.correction.length - 1].can_rush_production_order;
      let comment = null;
      if (patient.course.correction[patient.course.correction.length - 1].prescription !== null) {
        comment =
          patient.course.correction[patient.course.correction.length - 1].prescription.comment;
      }
      const cnamePostfix = patient && patient.course_version == COURSE_VERSION_V2 ? "_V2" : "";

      return (
        <div id="course-section">
          <h3 className="block" style={{ fontWeight: "900" }}>
            <FormattedMessage id="BLOCKHEAD_INSTRUCTIONS" />
          </h3>
          <div className="form-group">
            <label
              className="control-label"
              id="validation-course_id"
              style={{ fontWeight: "900", fontSize: "17px", marginBottom: "10px" }}
            >
              <FormattedMessage id="HEADER_INSTRUCTIONS" />
              <span style={{ marginRight: "4px" }} className="required" aria-required="true">
                *
              </span>
              <span className="prescription_tooltip_show">
                <i style={{ fontSize: "14px" }} className="icon-question" />
                <span className="d-inline-block prescription_tooltip">
                  <FormattedMessage id="course.type.tooltip" />
                  <a href={predict_link} rel="noopener noreferrer" target="_blank">
                    <FormattedMessage id="tooltip.chart" />
                  </a>
                  <br />
                  <FormattedMessage id="course.type.tooltip.sec" />
                </span>
              </span>
            </label>

            <div className="radio-list">
              <label id="course-label-5">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      name="course_id"
                      value={CASE_CBCT}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_CBCT}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <img
                    src="/img/deepcbct.png"
                    alt="cbct-icon"
                    style={{ width: "80px", height: "42px" }}
                  />
                  <span className="prescription_tooltip_show tw-px-2">
                    <i style={{ fontSize: "14px" }} className="icon-question" />
                    <span className="d-inline-block prescription_tooltip">
                      <FormattedMessage id="cbct.tooltip" />
                    </span>
                  </span>
                  <br />
                  <FormattedMessage id="C_UNLIMITED_COMMENT_USA" />
                </div>
              </label>

              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-1"
                      name="course_id"
                      value={CASE_FULL}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_FULL}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_FULL ? "boldText" : null}>
                    <FormattedMessage id="C_FULL_USA" />
                  </span>
                  <br />
                  <FormattedMessage id="C_FULL_COMMENT_USA" />
                </div>
              </label>

              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-2"
                      name="course_id"
                      value={CASE_SHORT}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_SHORT}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_SHORT ? "boldText" : null}>
                    <FormattedMessage id={`C_SHORT_USA${cnamePostfix}`} />
                  </span>
                  <br />
                  <FormattedMessage id={`C_SHORT_COMMENT_USA${cnamePostfix}`} />
                </div>
              </label>

              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-3"
                      name="course_id"
                      value={CASE_SINGLE_ARCH}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_SINGLE_ARCH}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_SINGLE_ARCH ? "boldText" : null}>
                    <FormattedMessage id="C_ONE_JAW_USA" />
                  </span>
                  <br />
                  <FormattedMessage id="C_ONE_JAW_COMMENT_USA" />
                </div>
              </label>

              {course_id && patient.course.course_id == CASE_SUPER_SHORT ? (
                <label>
                  <div className="radio">
                    <span>
                      <input
                        type="radio"
                        id="course-value-4"
                        name="course_id"
                        value={CASE_SUPER_SHORT}
                        onChange={(e) => this.selectCourse(e.target.value)}
                        defaultChecked={patient.course.course_id == CASE_SUPER_SHORT}
                      />
                    </span>
                  </div>
                  <div className="course_title">
                    <span className={course_id === CASE_SUPER_SHORT ? "boldText" : null}>
                      <FormattedMessage id="C_SUPER_SHORT_USA" />
                    </span>
                    <br />
                    <FormattedMessage id="C_SUPER_SHORT_COMMENT_USA" />
                  </div>
                </label>
              ) : null}

              <label>
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-6"
                      name="course_id"
                      value={CASE_PLAN_3D}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_PLAN_3D}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_PLAN_3D ? "boldText" : null}>
                    <FormattedMessage id="C_3D_PLAN_USA" />
                  </span>
                  <br />
                </div>
              </label>

              <label id="course-label-8">
                <div className="radio">
                  <span>
                    <input
                      type="radio"
                      id="course-value-8"
                      name="course_id"
                      value={CASE_RETAINERS}
                      onChange={(e) => this.selectCourse(e.target.value)}
                      defaultChecked={patient.course.course_id == CASE_RETAINERS}
                    />
                  </span>
                </div>
                <div className="course_title">
                  <span className={course_id === CASE_RETAINERS ? "boldText" : null}>
                    <FormattedMessage id="C_RETAINERS" />
                  </span>
                  <FormattedMessage id="C_RETAINERS_COMMENT" />
                </div>
              </label>
            </div>

            {course_id === CASE_CBCT || patient.deep_cbct === true ? (
              <div className="col-xs-12">
                <div className="alert alert-warning" id="course-alert-warning">
                  <FormattedMessage id="SCAN_UPLOAD_CT_WARNING" />
                </div>
              </div>
            ) : null}

            <div id="form_course_error" />
          </div>

          {deployedUSA() && rxTypeId === CASE_RX_TYPE_STANDARD ? (
            <div className="form-group">
              <label
                className="control-label"
                id="validation-condition"
                style={{ fontWeight: "900" }}
              >
                <FormattedMessage id="HEADER_CONDITION" />
              </label>
              <textarea
                data-matomo-mask
                className="form-control"
                id="patient-diagnosis-value"
                rows={5}
                name="diagnos"
                defaultValue={patient.diagnosis}
                onChange={(e) => this.addCondition(e.target.value)}
              />
            </div>
          ) : null}

          {deployedUSA() === true && rxTypeId === CASE_RX_TYPE_STANDARD ? (
            <div className="form-group">
              <label
                className="control-label"
                id="validation-comment"
                style={{ fontWeight: "900" }}
              >
                <FormattedMessage id="EXTRA_COMMENTS" />
                <span className="required" aria-required="true">
                  *
                </span>
              </label>
              <textarea
                data-matomo-mask
                className="form-control"
                rows={5}
                name="comment"
                defaultValue={comment}
                onChange={(e) => this.addComment(e.target.value)}
              />
            </div>
          ) : null}

          <ImpressionScanOptions material={material} />

          <div id="deep-cbct-section">
            {show_cbct_option || show_rush_prod ? (
              <h5 className="block" id="deep-cbct-label" style={{ fontWeight: "900" }}>
                <FormattedMessage id="ADDITIONAL_INSTRUCTIONS" />
              </h5>
            ) : null}
            {show_cbct_option ? (
              <label>
                <div className="checker">
                  <span>
                    <input
                      type="checkbox"
                      id="deep-cbct-value-1"
                      name="deep_cbct"
                      value={1}
                      disabled={
                        patient.status != S_UNFILLED_CASE &&
                        (patient.deep_cbct == true ||
                          (patient.course &&
                            [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(
                              patient.course.course_option_tag,
                            )))
                      }
                      onClick={(e) => this.selectDeepCBCTAnalysis(e.target)}
                      defaultChecked={
                        patient.deep_cbct ||
                        (patient.course &&
                          [CO_TAG_FULL_3_STEPS_CBCT, CO_TAG_TEEN_3_STEPS_CBCT].includes(
                            patient.course.course_option_tag,
                          ))
                      }
                    />
                  </span>
                </div>
                <FormattedMessage id="C_DEEP_CBCT_ANALYSIS_ITEM" />
              </label>
            ) : null}

            {show_rush_prod ? (
              <label htmlFor="rash" style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <input
                  id="rash"
                  style={{ marginTop: 0 }}
                  type="checkbox"
                  name="rush_correction"
                  onChange={(e) => this.props.rushCorrection(e.currentTarget.checked)}
                />
                <span className="tw-font-bold">
                  <FormattedMessage id="additional_service_rush" />{" "}
                </span>
                <span className="tw-text-[12px]">
                  <FormattedMessage id="rush_service_comment" />
                </span>
              </label>
            ) : null}
          </div>
        </div>
      );
    }
    return <Loader />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientUpdateInstructionsCourse));
